import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import UploadKmlButton from "./upload-kml-button";

class AoiButton extends React.Component {
  constructor(props) {
    super(props);
    this.updateInteraction = this.updateInteraction.bind(this);
    this.state = {
      activeAoiButton: false,
    };
  }

  updateInteraction() {
    const {
      mapAoiInteractionsActive,
      doMapAoiAddInteraction,
      doMapInteractionsClear,
      onAoiClick,
    } = this.props;
    onAoiClick(false);
    if (mapAoiInteractionsActive) {
      doMapInteractionsClear();
    } else {
      doMapAoiAddInteraction();
    }
  }

  render() {
    const { mapAoiInteractionsActive, doMapAoiUndo, missionsAoiItems } =
      this.props;
    const btnClass = classnames({
      btn: true,
      "btn-primary": true,
      active: mapAoiInteractionsActive,
    });
    return (
      <div className="btn-group mr-2">
        <button
          className={`btn btn-outline-primary`}
          onClick={doMapAoiUndo}
          title="Undo last Added/Deleted LZ points"
        >
          <i className="mdi mdi-undo"></i>
        </button>
        {this.state.activeAoiButton && (
          <button
            className={btnClass}
            title="Draw AOI directly on map"
            onClick={this.updateInteraction}
          >
            <i className="mdi mdi-pencil-outline" />
          </button>
        )}
        {this.state.activeAoiButton && (
          <UploadKmlButton onClick={() => this.props.onAoiClick(false)} />
        )}
        {!this.state.activeAoiButton && (
          <button
            className={btnClass}
            onClick={(e) => {
              this.setState({ activeAoiButton: true });
              this.props.onAoiClick(missionsAoiItems.length > 0);
            }}
          >
            {missionsAoiItems.length > 0 ? "Modify AOI" : "Create AOI"}
          </button>
        )}
      </div>
    );
  }
}

export default connect(
  "selectMapAoiInteractionsActive",
  "selectMissionsAoiItems",
  "doMapAoiAddInteraction",
  "doMapInteractionsClear",
  "doMapAoiUndo",
  AoiButton
);
