import React from "react";
import { connect } from "redux-bundler-react";
import Map from "../../../_shared/map/map";
import LayerToggle from "./layer-toggle";
import Geocoder from "./geocoder";
import ReverseGeocoder from "./reverse-geocoder";
import AoiButton from "./add-aoi-button";
import LzButton from "./add-lz-button";
import CaptureThumbnailButton from "./capture-thumbnail-button";
import CaptureFigureButton from "./capture-figure-button";
import WeatherWidget from "./weather-widget";
import Loader from "../../../../app-components/loader";
import RoleFilter from "../../../../app-containers/context-providers/role-filter";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import AdvisoryList from "./advisory-list";
import "ol/ol.css";
import UploadKmlButton from "./upload-kml-button";
import "./popup.css";
import SectionalLegendButton from "./sectional-legend-button";

const heightBuffer = 300;

class MapTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - heightBuffer,
      currentSearch: "geo",
      showWarningBanner: false,
    };
    this.resetHeight = this.resetHeight.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleBasemapSelect = this.handleBasemapSelect.bind(this);
    this.renderBasemapToggles = this.renderBasemapToggles.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resetHeight);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resetHeight);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyUp(e) {
    const { doMapLzClearSelected, doMapLzDeleteSelected } = this.props;
    if (e.keyCode === 27) {
      doMapLzClearSelected();
    } else if (e.keyCode === 46 || e.keyCode === 8) {
      doMapLzDeleteSelected();
    }
  }

  resetHeight() {
    this.setState({
      height: window.innerHeight - heightBuffer,
    });
  }

  renderMap() {
    const {
      missionsAoiIsLoading,
      missionsLzIsLoading,
      doMapInitialize,
      mapInitializeProps,
    } = this.props;
    if (missionsAoiIsLoading || missionsLzIsLoading)
      return <Loader opt="dissolve-cube" />;
    return (
      <Map initializeFn={doMapInitialize} initializeProps={mapInitializeProps}>
        <AdvisoryList />
      </Map>
    );
  }

  handleBasemapSelect(e) {
    const { doBasemapsChange } = this.props;
    const idx = e.currentTarget.dataset.idx;
    doBasemapsChange(Number(idx));
  }

  renderBasemapToggles() {
    const { basemaps, basemapsActiveIdx: idx } = this.props;
    let icons = ["mdi-earth", "mdi-drawing", "mdi-map"];
    return (
      <div className="btn-group">
        {basemaps.map((basemap, i) => {
          return (
            <button
              onClick={this.handleBasemapSelect}
              name="basemap"
              data-idx={i}
              key={i}
              className={
                idx !== i ? "btn btn-outline-primary" : "btn btn-primary"
              }
              title={basemap.name}
            >
              <i className={`mdi ${icons[i]} mdi-14px`} />
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { height, showWarningBanner } = this.state;
    const { hazardsIsFetching, hazardsFeaturesIsLoading } = this.props;

    return (
      <div onKeyUpCapture={this.handleKeyUp}>
        {showWarningBanner && (
          <div className="alert alert-warning">
            Drawing or uploading a new AOI to the map will overwrite the
            existing shape on the map.
          </div>
        )}
        <div className="clearfix">
          <RoleFilter
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            alt={() => {
              return <AoiButton disabled={true} />;
            }}
          >
            <MissionStatusFilter
              allowStatus={{
                approval_status: ["draft"],
              }}
              alt={() => {
                return <AoiButton disabled={true} />;
              }}
            >
              <AoiButton
                onAoiClick={(showWarningBanner) =>
                  this.setState({ showWarningBanner })
                }
              />
            </MissionStatusFilter>
          </RoleFilter>
          <RoleFilter
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            alt={() => {
              return <LzButton disabled={true} />;
            }}
          >
            <MissionStatusFilter
              allowStatus={{
                approval_status: ["draft"],
              }}
              alt={() => {
                return <LzButton disabled={true} />;
              }}
            >
              <LzButton />
            </MissionStatusFilter>
          </RoleFilter>
          {this.renderBasemapToggles()}
          <div className="float-right mb-2">
            <SectionalLegendButton />
            <RoleFilter
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              alt={() => {
                return <CaptureFigureButton disabled={true} />;
              }}
            >
              <MissionStatusFilter
                allowStatus={{
                  approval_status: ["draft"],
                }}
                alt={() => {
                  return <CaptureFigureButton disabled={true} />;
                }}
              >
                <CaptureFigureButton />
              </MissionStatusFilter>
            </RoleFilter>
            <div className="d-inline-flex align-items-center">
              <div
                className="btn-group mr-1"
                role="group"
                aria-label="Latitude Longitude Coordinate Search"
              >
                <button
                  type="button"
                  className={`btn btn-${
                    this.state.currentSearch === "latLong"
                      ? "primary"
                      : "outline-primary"
                  }`}
                  onClick={() => this.setState({ currentSearch: "latLong" })}
                  title="Enter coordinates (tip: S and W are negative values)"
                >
                  Lat / Long
                </button>
                <button
                  type="button"
                  className={`btn btn-${
                    this.state.currentSearch === "geo"
                      ? "primary"
                      : "outline-primary"
                  }`}
                  onClick={(e) => this.setState({ currentSearch: "geo" })}
                >
                  Geo
                </button>
              </div>
              {this.state.currentSearch !== "geo" ? (
                <div className="inline mr-2">
                  <ReverseGeocoder />
                </div>
              ) : (
                <div className="inline mr-2">
                  <Geocoder />
                </div>
              )}
              <div className="inline">
                <LayerToggle maxHeight={height - heightBuffer} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "relative", height: height }}>
          {/* {(hazardsIsFetching || hazardsFeaturesIsLoading) && (
            <div id="loadingOverlay">
              <Loader />
            </div>
          )} */}
          <div>{this.renderMap()}</div>
          <div id="popup" style={{ display: "none" }}>
            <button id="popup-closer" className="ol-popup-closer"></button>
            <div id="popup-content"></div>
          </div>
        </div>
        {/* <WeatherWidget /> */}
      </div>
    );
  }
}

export default connect(
  "selectMapInitializeProps",
  "selectMissionsAoiIsLoading",
  "selectMissionsLzIsLoading",
  "doMapInitialize",
  "doMapLzClearSelected",
  "doMapLzDeleteSelected",
  "selectHazardsIsFetching",
  "selectHazardsFeaturesIsLoading",
  "doBasemapsChange",
  "selectBasemapsActiveIdx",
  "selectBasemaps",
  MapTab
);
