import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsKml",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/kml",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/kml/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/kml",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/kml/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "MISSIONSKML_SAVE_FINISHED",
    "MISSIONSAOI_SAVE_FINISHED",
    "ACTIVE_TAB_UPDATE",
  ],
  forceFetchActions: ["URL_UPDATED"],
  addons: {
    selectMissionsKmlGeoJSON: createSelector(
      "selectMissionsKmlItems",
      (items) => {
        return items.map((feature) => ({
          type: "FeatureCollection",
          features: feature.shapes.map((f) => {
            return {
              type: "Feature",
              geometry: JSON.parse(f),
              properties: { id: feature.id },
            };
          }),
        }));
      }
    ),
  },
});
