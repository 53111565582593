import { createSelector } from "redux-bundler";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Modify from "ol/interaction/Modify";
import Draw from "ol/interaction/Draw";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import { debounce } from "lodash";
import buffer from "@turf/buffer";

const geoJSON = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});

export default {
  name: "mapKml",
  getReducer: () => {
    const initialData = {
      layer: null,
      bufferLayer: null,
      _isLoading: false,
      _shouldAddToMap: false,
      _shouldInitialize: true,
      _shouldLoadFromMission: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_KML_LOAD_FROM_MISSION_STARTED":
        case "MAP_KML_LOAD_FROM_MISSION_ABORTED":
        case "MAP_KML_LOAD_FROM_MISSION_ERROR":
        case "MAP_KML_LOAD_FROM_MISSION_FINISHED":
        case "MAP_KML_LAYERS_INITIALIZE_STARTED":
        case "MAP_KML_ADD_LAYER_FINISHED":
        case "MAP_KML_ADD_TO_MAP_STARTED":
        case "MAP_KML_ADD_TO_MAP_FINISHED":
        case "MAP_KML_REMOVE_LAYER_FINISHED":
        case "MAP_KML_BUFFER_KML":
          return Object.assign({}, state, payload);
        case "MAP_INITIALIZED":
          return Object.assign({}, state, {
            ...payload,
            _shouldInitialize: true,
          });
        case "MAP_KML_LAYERS_INITIALIZE_FINISHED":
          return Object.assign({}, state, {
            ...payload,
            _shouldLoadFromMission: true,
          });
        default:
          return state;
      }
    };
  },

  doMapKmlAddToMap:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_KML_ADD_TO_MAP_STARTED",
        payload: {
          _shouldAddToMap: false,
        },
      });

      const map = store.selectMap();
      if (!map) {
        dispatch({
          type: "MAP_KML_ADD_TO_MAP_FINISHED",
          payload: {
            _shouldAddToMap: false,
          },
        });
        return;
      }
      const kmlLayer = store.selectMapKmlLayer();
      const bufferLayer = store.selectMapKmlBufferLayer();
      const isLayerExistsInMap = (argLayer) =>
        map.getLayers().getArray().includes(argLayer);
      !isLayerExistsInMap(kmlLayer) && map.addLayer(kmlLayer);
      !isLayerExistsInMap(bufferLayer) && map.addLayer(bufferLayer);
      const vw = map.getView();
      const src = bufferLayer.getSource();
      if (src.getFeatures().length) {
        const extent = src.getExtent();
        vw.fit(extent);
      }

      dispatch({
        type: "MAP_KML_ADD_TO_MAP_FINISHED",
      });
    },

  doMapKmlInitialize:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "MAP_KML_LAYERS_INITIALIZE_STARTED",
        payload: {
          _shouldInitialize: false,
        },
      });

      const src = new VectorSource();
      const layer = new VectorLayer({
        source: src,
        style: [
          new Style({
            stroke: new Stroke({
              color: "rgba(255,255,255,0.7)",
              width: 6,
            }),
          }),
          new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "rgba(255, 38, 38, 1)",
              width: 2,
            }),
          }),
        ],
      });

      const bufferSrc = new VectorSource();
      const bufferLayer = new VectorLayer({
        source: bufferSrc,
        style: new Style({
          fill: new Fill({
            color: "rgba(244, 66, 226, 0.1)",
          }),
          stroke: new Stroke({
            color: "#f442e2",
            width: 2,
          }),
        }),
      });
      src.on("addfeature", store.doMapKmlBuffer);
      src.on("changefeature", debounce(store.doMapKmlBuffer, 200));

      dispatch({
        type: "MAP_KML_LAYERS_INITIALIZE_FINISHED",
        payload: {
          layer: layer,
          bufferLayer: bufferLayer,
        },
      });
    },

  doMapKmlBuffer:
    () =>
    ({ dispatch, store }) => {
      const kmlLayer = store.selectMapKmlLayer();
      const kmlSrc = kmlLayer.getSource();
      const kmlFeatures = kmlSrc.getFeatures();
      const kmlFeaturesGeoJSON = geoJSON.writeFeaturesObject(kmlFeatures, {
        rightHanded: true,
        decimals: 5,
      });
      const bufferLayer = store.selectMapKmlBufferLayer();
      const bufferSrc = bufferLayer.getSource();
      bufferSrc.clear();
      try {
        const newBuffer = buffer(kmlFeaturesGeoJSON, 5, {
          units: "miles",
          steps: 100,
        });

        const newBufferFeatures = geoJSON.readFeatures(newBuffer);
        bufferSrc.addFeatures(newBufferFeatures);
        dispatch({
          type: "MAP_KML_BUFFER_KML",
          payload: {
            version: new Date().toTimeString(),
            _shouldAddToMap: true,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },

  doMapKmlLoadFromMission:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_KML_LOAD_FROM_MISSION_STARTED",
        payload: {
          _shouldLoadFromMission: false,
          _isLoading: true,
        },
      });

      const layer = store.selectMapKmlLayer();
      if (!layer) {
        dispatch({
          type: "MAP_KML_LOAD_FROM_MISSION_FINISHED",
          payload: {
            _isLoading: false,
          },
        });
        return;
      }
      const src = layer.getSource();

      const featureCollectionArray = store.selectMissionsKmlGeoJSON();
      const features = featureCollectionArray.map((fc) =>
        geoJSON.readFeatures(fc)
      );
      features.forEach((f) => src.addFeatures(f));
      dispatch({
        type: "MAP_KML_LOAD_FROM_MISSION_FINISHED",
        payload: {
          _isLoading: false,
        },
      });
    },

  doMapClearFeatures:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_KML_CLEAR_SOURCE",
      });

      const layer = store.selectMapKmlLayer();

      if (layer) {
        const src = layer.getSource();
        src.clear();
      }

      const bufferLayer = store.selectMapKmlBufferLayer();
      if (bufferLayer) {
        const bufferSrc = bufferLayer.getSource();
        bufferSrc.clear();
      }

      const aoiLayer = store.selectMapAoiLayer();

      if (aoiLayer) {
        const src = aoiLayer.getSource();
        src.clear();
      }

      const bufferAoiLayer = store.selectMapAoiBufferLayer();
      if (bufferAoiLayer) {
        const bufferAoiSrc = bufferAoiLayer.getSource();
        bufferAoiSrc.clear();
      }
      store.doMapKmlLoadFromMission();
    },

  selectMapKmlLayers: (state) => state.mapKml._kmlLayers,
  selectMapKmlIsLoading: (state) => state.mapKml._isLoading,
  selectMapKmlItems: (state) => state.mapKml,
  selectMapKmlLayer: (state) => state.mapKml.layer,
  selectMapKmlBufferLayer: (state) => state.mapKml.bufferLayer,

  selectMapKmlBufferExtent: (state) => {
    if (!state.mapKml.bufferLayer) return [];
    else {
      let extent = state.mapKml.bufferLayer.getSource().getExtent();
      return {
        minX: extent[0],
        minY: extent[1],
        maxX: extent[2],
        maxY: extent[3],
      };
    }
  },
  selectMapKmlAsGeoJSON: createSelector("selectMapKmlLayer", (layer) => {
    if (!layer)
      return {
        type: "FeatureCollection",
        features: [],
      };
    const src = layer.getSource();
    const geojson = JSON.parse(geoJSON.writeFeatures(src.getFeatures()));
    return geojson;
  }),

  selectMapKmlBufferAsGeoJSON: createSelector(
    "selectAppTime",
    "selectMapKmlBufferLayer",
    (t, layer) => {
      if (!layer)
        return {
          type: "FeatureCollection",
          features: [],
        };
      const src = layer.getSource();
      const geojson = JSON.parse(geoJSON.writeFeatures(src.getFeatures()));
      return geojson;
    }
  ),

  selectMapKmlBufferPolygon: createSelector(
    "selectMapKmlBufferAsGeoJSON",
    (geojson) => {
      if (!geojson.features || !geojson.features.length)
        return { rings: [], spatialReference: { wkid: 4326 } };
      else
        return {
          rings: geojson.features[0].geometry.coordinates,
          spatialReference: { wkid: 4326 },
        };
    }
  ),

  reactKmlShouldInitialize: (state) => {
    if (state.mapKml._shouldInitialize)
      return { actionCreator: "doMapKmlInitialize" };
  },

  reactKmlShouldLoadFromMission: (state) => {
    if (state.mapKml._shouldLoadFromMission)
      return { actionCreator: "doMapKmlLoadFromMission" };
  },

  reactKmlShouldAddToMap: (state) => {
    if (state.mapKml._shouldAddToMap)
      return { actionCreator: "doMapKmlAddToMap" };
  },
};
