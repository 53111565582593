import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Select from "ol/interaction/Select";
import GeoJSON from "ol/format/GeoJSON";
import Circle from "ol/style/Circle";
import { debounce } from "lodash";
import { get, transform } from "ol/proj";
import { createSelector } from "redux-bundler";
import { createOlMap } from "../utils/map-utils";
import centroid from "@turf/centroid";
import { Overlay } from "ol";
import { getCenter } from "ol/extent";

const geoJSON = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});

const defaultViewState = {
  center: [-98.579, 39.828],
  zoom: 4,
  rotation: 0,
  minZoom: 0,
  maxZoom: 22,
};
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

const polygonToCentroid = (featuresCollection) => ({
  type: "FeatureCollection",
  features: featuresCollection.features.map((feature) => {
    let c = centroid(feature);
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: c.geometry.coordinates,
      },
      properties: {
        ...feature.properties,
      },
    };
  }),
});

export default {
  name: "dashboardAoi",

  getReducer() {
    const initialData = {
      map: null,
      geoProjection: get("EPSG:4326"),
      webProjection: get("EPSG:3857"),
      data: {},
      flightDuties: { RP: 0, SRP: 0, RPI: 0 },
      _isLoading: false,
      _shouldAddToMap: false,
      _shouldFetchAoi: false,
      _shouldInitialize: true,
      zoom: defaultViewState.zoom,
      rotation: defaultViewState.rotation,
      minZoom: defaultViewState.minZoom,
      maxZoom: defaultViewState.maxZoom,
      center: defaultViewState.center,
      crewmemberCount: 0,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AOI_FETCH_STARTED":
        case "AOI_FETCH_ERROR":
        case "DASHBOARD_AOI_ADD_TO_MAP_STARTED":
        case "DASHBOARD_AOI_INITIALIZE_STARTED":
        case "DASHBOARD_AOI_INITIALIZE_FINISHED":
        case "DASHBOARD_AOI_RENDER_FEATURE_STARTED":
        case "DASHBOARD_AOI_RENDER_FEATURE_FINISHED":
        case "DASHBOARD_MAP_UPDATED_VIEW_STATE":
        case "SET_SELECTED_MISSION":
          return Object.assign({}, state, payload);
        case "DASHBOARD_MAP_INITIALIZED":
          return { ...state, ...payload, _shouldAddToMap: true };
        case "DASHBOARD_AOI_ADD_TO_MAP_FINISHED":
          return Object.assign({}, state, { _shouldFetchAoi: true });
        case "AOI_FETCH_FINISHED":
          return { ...state, ...payload, _shouldRenderFeatures: true };
        default:
          return state;
      }
    };
  },

  selectDashboardAoiIsLoading: (state) => state.dashboardAoi._isLoading,

  selectDashboardAoiItems: (state) => {
    return state.dashboardAoi.data;
  },
  selectDashboardAoiFlightDuties: (state) => {
    return state.dashboardAoi.flightDuties;
  },

  selectDashboardAoiCrewmemberCount: (state) => {
    return state.dashboardAoi.crewmemberCount;
  },
  selectDashboardAoiLayer: (state) => {
    return state.dashboardAoi.layer;
  },

  selectDashboardAoiBufferLayer: (state) => {
    return state.dashboardAoi.bufferLayer;
  },

  selectDashboardAoiDraw: (state) => {
    return state.dashboardAoi.draw;
  },

  selectDashboardAoiModify: (state) => {
    return state.dashboardAoi.modify;
  },

  selectDashboardAoiGeoJSONCentroids: createSelector(
    "selectDashboardAoiItems",
    (items) => {
      if (!items || !items.features) return;
      return polygonToCentroid(items);
    }
  ),

  selectDashboardMap: (state) => {
    return state.dashboardAoi.map;
  },

  selectDashboardMapGeoProjection: (state) => {
    return state.dashboardAoi.geoProjection;
  },

  selectDashboardMapWebProjection: (state) => {
    return state.dashboardAoi.webProjection;
  },
  selectDashboardMapCenter: (state) => {
    return state.dashboardAoi.center;
  },

  selectDashboardMapZoom: (state) => {
    return state.dashboardAoi.zoom;
  },

  selectDashboardMapRotation: (state) => {
    return state.dashboardAoi.rotation;
  },

  selectDashboardMapMinZoom: (state) => {
    return state.dashboardAoi.minZoom;
  },

  selectDashboardMapMaxZoom: (state) => {
    return state.dashboardAoi.maxZoom;
  },
  selectDashboardMapInitializeProps: createSelector(
    "selectDashboardMapCenter",
    "selectDashboardMapZoom",
    "selectDashboardMapRotation",
    "selectDashboardMapMinZoom",
    "selectDashboardMapMaxZoom",
    (center, zoom, rotation, minZoom, maxZoom) => {
      return {
        center: center,
        zoom: zoom,
        rotation: rotation,
        minZoom: minZoom,
        maxZoom: maxZoom,
      };
    }
  ),

  selectDashboardMapSelectedMission: (state) =>
    state.dashboardAoi.selectedMission,

  doDashboardMapInitialize:
    (options) =>
    ({ dispatch, store }) => {
      const geoProjection = store.selectDashboardMapGeoProjection();
      const webProjection = store.selectDashboardMapWebProjection();
      const map = createOlMap(options, geoProjection, webProjection);
      map.on("moveend", debounce(store.doDashboardMapUpdateViewState, 200));
      dispatch({ type: "DASHBOARD_MAP_INITIALIZED", payload: { map: map } });
    },

  doDashboardMapUpdateViewState:
    () =>
    ({ dispatch, store }) => {
      const epsg4326 = store.selectDashboardMapGeoProjection();
      const epsg3857 = store.selectDashboardMapWebProjection();
      const map = store.selectDashboardMap();
      const view = map.getView();
      const center = transform(view.getCenter(), epsg3857, epsg4326);
      const rotation = view.getRotation();
      const zoom = view.getZoom();
      const minZoom = view.getMinZoom();
      const maxZoom = view.getMaxZoom();
      dispatch({
        type: "DASHBOARD_MAP_UPDATED_VIEW_STATE",
        payload: {
          center: center,
          rotation: rotation,
          zoom: zoom,
          minZoom: minZoom,
          maxZoom: maxZoom,
        },
      });
    },

  doDashboardMapResetViewState:
    () =>
    ({ dispatch, store }) => {
      const { center, zoom, rotation, minZoom, maxZoom } = defaultViewState;
      dispatch({
        type: "DASHBOARD_MAP_UPDATED_VIEW_STATE",
        payload: {
          shouldResetViewState: false,
          center: center,
          rotation: rotation,
          zoom: zoom,
          minZoom: minZoom,
          maxZoom: maxZoom,
        },
      });
    },
  doDashboardAoiAddToMap:
    () =>
    ({ dispatch, store }) => {
      const map = store.selectDashboardMap();
      if (!map) return;
      dispatch({
        type: "DASHBOARD_AOI_ADD_TO_MAP_STARTED",
        payload: {
          _shouldAddToMap: false,
        },
      });
      const aoiLayer = store.selectDashboardAoiLayer();
      map.addLayer(aoiLayer);

      dispatch({
        type: "DASHBOARD_AOI_ADD_TO_MAP_FINISHED",
      });
    },

  doDashboardAoiInitialize:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "DASHBOARD_AOI_INITIALIZE_STARTED",
        payload: {
          _shouldInitialize: false,
        },
      });

      const src = new VectorSource();
      const layer = new VectorLayer({
        source: src,
        style: (feature) =>
          new Style({
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color:
                  feature.get("mission_status") === "active"
                    ? "#50C878"
                    : "#0096FF",
              }),
              stroke: new Stroke({
                color: "#ffffff",
                width: 2,
              }),
            }),
          }),
      });
      const draw = new Draw({
        source: src,
        type: "Point",
      });

      dispatch({
        type: "DASHBOARD_AOI_INITIALIZE_FINISHED",
        payload: {
          layer: layer,
          draw: draw,
        },
      });
    },

  doDashboardAoiFetch:
    () =>
    ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "AOI_FETCH_STARTED",
        payload: { _isLoading: true, _shouldFetchAoi: false },
      });
      apiGet(
        `/orgs/${store.selectOrgsByRoute().slug}/map?rollup=true`,
        (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "AOI_FETCH_ERROR",
              payload: {
                _err: { err: err, response: response },
                _isLoading: false,
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            let mapData =
              data.aoiMap.features.length > 0
                ? data.aoiMap
                : data.kmlMap.features.length > 0
                ? {
                    type: "FeatureCollection",
                    features: data.kmlMap.features.map((f) => {
                      return {
                        type: "Feature",
                        geometry: JSON.parse(f.geometry[0]),
                        properties: f.properties,
                      };
                    }),
                  }
                : {};

            dispatch({
              type: "AOI_FETCH_FINISHED",
              payload: {
                data: mapData,
                flightDuties: data.flight_duties,
                crewmemberCount: data.crewmemberCount,
                _isLoading: false,
              },
            });
          }
        }
      );
    },

  doDashboardAoiRenderFeatures:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "DASHBOARD_AOI_RENDER_FEATURE_STARTED",
        payload: {
          _shouldRenderFeatures: false,
          _isLoading: true,
        },
      });
      store.doDashboardClickHandle();

      const layer = store.selectDashboardAoiLayer();
      const src = layer.getSource();
      src.clear();

      const featureCollection = store.selectDashboardAoiGeoJSONCentroids();
      const features = geoJSON.readFeatures(featureCollection);
      src.addFeatures(features);

      dispatch({
        type: "DASHBOARD_AOI_RENDER_FEATURE_FINISHED",
        payload: {
          _isLoading: false,
        },
      });
    },
  doDashboardClickHandle:
    () =>
    ({ store, dispatch }) => {
      let map = store.selectDashboardMap();
      if (map) {
        let container = document.getElementById("dashboard-popup");
        let content = document.getElementById("dashboard-popup-content");
        let closer = document.getElementById("dashboard-popup-closer");
        if (container) {
          container.style.display = "block";
        }
        let overlay = new Overlay({
          element: container,
          positioning: "bottom-center",
          stopEvent: false,
          offset: [20, -20],
        });
        if (closer) {
          closer.onclick = function () {
            dispatch({
              type: "SET_SELECTED_MISSION",
              payload: { selectedMission: null },
            });
            overlay.setPosition(undefined);
            closer.blur();
            return false;
          };
        }
        map.on("click", (e, idx) => {
          map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
            let name = feature.get("mission_name");
            let orgName = feature.get("org_name");
            let startDate = feature.get("date_start").split("T")[0];
            let endDate = feature.get("date_end").split("T")[0];
            let atpms = feature.get("atpms");
            let atpmList = "";
            atpms.forEach((a) => {
              atpmList += `<li style="display:flex;"><p style="margin-bottom: 2px;">${
                a.name
              }:   ${formatPhoneNumber(a.phone)}</p></li>`;
            });
            content.innerHTML = `<div>
                <h6 style=color:#20a8d8>${name}</h6>
                <p><b style="padding-right:4px">Dates:</b>${startDate} - ${endDate}</p>
                <p><b style="padding-right:4px">Organization:</b>${orgName}</p>
                ${
                  atpms && atpms.length > 0
                    ? `<div style="display: flex;flex-direction: column;">
                <p style="margin-bottom: 4px;"><b>ATPM:</b></p>
                <ul style="margin: 0px; padding: 0px;">
                  ${atpmList}
                </div>`
                    : ""
                }`;
            overlay.setPosition(getCenter(feature.getGeometry().getExtent()));
            map.addOverlay(overlay);
            dispatch({
              type: "SET_SELECTED_MISSION",
              payload: { selectedMission: feature.get("id") },
            });
          });
        });
      }
    },

  reactDashboardAoiShouldInitialize: (state) => {
    if (state.dashboardAoi._shouldInitialize)
      return { actionCreator: "doDashboardAoiInitialize" };
  },

  reactDashboardAoiShouldAddToMap: (state) => {
    if (state.dashboardAoi._shouldAddToMap)
      return { actionCreator: "doDashboardAoiAddToMap" };
  },

  reactDashboardAoiShouldLoadFromMission: (state) => {
    if (state.dashboardAoi._shouldRenderFeatures)
      return { actionCreator: "doDashboardAoiRenderFeatures" };
  },

  reactDashboardAoiShouldFetch: (state) => {
    if (state.dashboardAoi._shouldFetchAoi)
      return { actionCreator: "doDashboardAoiFetch" };
  },
};
