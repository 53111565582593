import React from "react";
import FileUploader from "./file-uploader";
import FileList from "./file-list";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../read-only-notification";
import Dropdown from "../item-board/dropdown";
import { findIndex, orderBy, uniq, flatten } from "lodash";
import classnames from "classnames";
class FileUploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      searchTerm: "",
      sortProperty: { attr: "filename", label: "Name" },
      sortDirection: "asc",
      sortOptions: props.sortOptions
        ? props.sortOptions
        : [
            {
              update: { sortProperty: { attr: "filename", label: "Name" } },
              icon: "mdi mdi-sort-alphabetical-variant",
              text: "Name",
              handler: this.updateSortProperty,
              displayValue: "filename",
              exitOnClick: true,
            },
            {
              update: {
                sortProperty: { attr: "create_date", label: "Date Created" },
              },
              icon: "mdi mdi-clock-alert-outline",
              text: "Date Created",
              handler: this.updateSortProperty,
              displayValue: "create_date",
              exitOnClick: true,
            },
          ],
      categoryIdx: 0,
      categories: ["All"],
      activeCategory: "All",
    };
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.renderUploader = this.renderUploader.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderSorting = this.renderSorting.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    this.updateSortDirection = this.updateSortDirection.bind(this);
    this.updateSortProperty = this.updateSortProperty.bind(this);

    this.updateSelectedTab = this.updateSelectedTab.bind(this);
  }
  componentDidMount() {
    const { items, enableCategories } = this.props;
    if (enableCategories) {
      let uniqCategories = uniq(
        flatten(
          items.map((item) => {
            return item.categories && item.categories.length > 0
              ? item.categories.split("~,~")
              : [];
          })
        )
      );
      this.setState({ ...this.state, categories: ["All", ...uniqCategories] });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { enableCategories } = this.props;
    if (enableCategories) {
      if (prevProps !== this.props) {
        const { items } = prevProps;
        let uniqCategories = uniq(
          flatten(
            items.map((item) => {
              return item.categories && item.categories.length > 0
                ? item.categories.split("~,~")
                : [];
            })
          )
        );
        this.setState({
          ...this.state,
          categories: ["All", ...uniqCategories],
        });
      }
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    this.setState({
      editing: false,
    });
  }

  updateSearch(e) {
    const val = e.currentTarget.value;
    this.setState({
      searchTerm: val,
    });
  }

  renderHeader() {
    const { header, title, allowRoles, required } = this.props;
    const { editing } = this.state;
    if (!header) return null;
    return (
      <div className="card-header">
        <div className="float-right">
          <RoleFilter allowRoles={allowRoles} alt={ReadOnly}>
            <button
              disabled={editing}
              className="btn btn-sm btn-ghost-primary"
              onClick={this.enableEditing}
            >
              <i className="mdi mdi-pencil icon-inline"></i>
              Edit
            </button>
          </RoleFilter>
        </div>
        <div className="d-flex">
          <i className="mdi mdi-file-multiple icon-inline mr-2" />{" "}
          {title || "Associated Documents"}
          {required && <div className="text-muted ml-2">Required</div>}
        </div>
      </div>
    );
  }

  renderUploader() {
    const { editing } = this.state;
    const { postUrl, onUpload, rel, data, disabledEditing, accept } =
      this.props;
    if (!editing || disabledEditing) return null;
    return (
      <div className="mt-2">
        <FileUploader
          onUpload={onUpload}
          url={postUrl}
          rel={rel}
          data={data}
          accept={accept}
        />
      </div>
    );
  }

  renderActions() {
    const { editing } = this.state;
    const { disabledEditing } = this.props;
    if (!editing || disabledEditing) return null;
    return (
      <div className="float-right mt-2">
        <button
          onClick={this.cancelEditing}
          className="btn btn-sm btn-secondary"
        >
          Done
        </button>
      </div>
    );
  }

  renderSearch() {
    const { searchable } = this.props;
    const { searchTerm } = this.state;
    if (!searchable) return null;
    return (
      <div className="w-100 mb-2">
        <input
          className="form-control"
          type="text"
          onChange={this.updateSearch}
          value={searchTerm}
          placeholder="Search Documents..."
        ></input>
      </div>
    );
  }
  updateSortDirection = () => {
    const { sortDirection } = this.state;
    let toggledVal = sortDirection === "asc" ? "desc" : "asc";
    this.setState({ ...this.state, sortDirection: toggledVal });
  };
  updateSortProperty = (update) => {
    this.setState({ ...this.state, ...update });
  };
  updateSelectedTab = (e) => {
    let selectedTab = e.currentTarget.value;
    this.setState({
      ...this.state,
      categoryIdx: selectedTab,
      activeCategory: this.state.categories[selectedTab],
    });
  };

  renderSorting() {
    const { sortDirection, sortProperty, sortOptions } = this.state;
    let sortDirIcon = classnames({
      mdi: true,
      "mdi-sort-ascending": sortDirection === "asc",
      "mdi-sort-descending": sortDirection === "desc",
    });

    if (this.props.sortable) {
      return (
        <div className="badge badge-light d-flex justify-content-between py-0 pr-0 my-1">
          <div className="my-auto text-dark">Sort by:</div>
          <div className="btn-group mb-auto mr-0 ml-auto">
            <Dropdown
              btnCls="ml-2 btn-sm btn-ghost-dark"
              direction="dropdown-menu-right"
              title="Sort by"
              options={sortOptions}
              stateValueAsTitle={true}
              selectedIdx={findIndex(
                sortOptions,
                (o) => o.value === sortProperty.attr
              )}
              selectedVal={sortProperty.attr}
            />
            <button
              className="btn btn-sm btn-secondary"
              type="button"
              onClick={this.updateSortDirection}
            >
              <span className={sortDirIcon} />
            </button>
          </div>
        </div>
      );
    } else return null;
  }

  render() {
    const {
      editing,
      searchTerm,
      sortDirection,
      sortProperty,
      categoryIdx,
      activeCategory,
    } = this.state;
    const {
      items,
      onRemove,
      removeUrl,
      rel,
      isLoading,
      showDate,
      scrollable,
      sortable,
      enableCategories,
      additionalColumns,
    } = this.props;

    const filteredItems = items.filter((item) => {
      const matcher = new RegExp(searchTerm, "ig");
      let itemCategories = item.categories ? item.categories.split("~,~") : [];
      let categoryMatches =
        itemCategories.includes(activeCategory) || activeCategory === "All";
      return matcher.test(item.filename) && categoryMatches;
    });

    const resultItems = sortable
      ? orderBy(filteredItems, [sortProperty.attr], [sortDirection])
      : filteredItems;
    return (
      <div className="card">
        {this.renderHeader()}
        <div
          className="card-body"
          style={
            scrollable
              ? { height: this.props.items.length >= 3 ? 205 : "auto" }
              : null
          }
        >
          {enableCategories && (
            <div className="nav nav-tabs mb-4">
              {this.state.categories.map((cat, idx) => {
                return (
                  <li
                    key={idx}
                    className="nav-item lib-category-tab-item"
                    value={idx}
                    onClick={this.updateSelectedTab}
                  >
                    <a
                      className={`nav-link ${
                        idx === categoryIdx ? "active" : ""
                      }`}
                    >
                      {cat}
                    </a>
                  </li>
                );
              })}
            </div>
          )}

          {this.renderSearch()}
          {sortable && (
            <div className="d-flex justify-content-end">
              {this.renderSorting()}
            </div>
          )}
          <FileList
            items={resultItems}
            isLoading={isLoading}
            editing={editing}
            onRemove={onRemove}
            removeUrl={removeUrl}
            rel={rel}
            showDate={showDate}
            scrollable={scrollable}
            sortable={sortable}
            additionalColumns={additionalColumns}
          />
          {this.renderUploader()}
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default FileUploadCard;
