import { createSelector } from "redux-bundler";
import GeoJSON from "ol/format/GeoJSON";
import VectorTileLayer from "ol/layer/Vector";
import VectorTileSource from "ol/source/Vector";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { multiPolygon, point, polygon } from "@turf/helpers";
import { toWgs84 } from "@turf/projection";
import booleanIntersects from "@turf/boolean-intersects";
import { Overlay } from "ol";
import { getCenter } from "ol/extent";

const geoJSON = new GeoJSON({
  dataProjection: "EPSG:3857",
  featureProjection: "EPSG:3857",
});
export default {
  name: "hazards",

  getReducer: () => {
    const hazardsLayers = [
      "airport",
      // "hospital",
      // "heliport",
      "power_plant",
      "school",
      // "prison",
      // "wildfire",
    ];

    const layerUrls = {
      airport: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/US_Airport/FeatureServer/0",
        color: "rgba(65,105,225, 0.8)", //dark blue,
        layerName: "Airport Areas",
      },
      class_b: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='B'`,
        color: "#1469aa", //green
        layerName: "Class B Airspace",
      },
      class_c: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='C'`,
        color: "#862051", //light blue
        layerName: "Class C Airspace",
      },
      class_d: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='D'`,
        color: "blue",
        dashed: true,
        layerName: "Class D Airspace",
      },
      class_e0: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E0 Airspace",
      },
      class_e1: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E1 Airspace",
      },
      class_e2: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E2 Airspace",
      },
      class_e3: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E3 Airspace",
      },
      class_e4: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E4 Airspace",
      },
      class_e5: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/arcgis/rest/services/Class_Airspace/FeatureServer/0",
        query: `CLASS='E'`,
        color: "#862051",
        dashed: true,
        layerName: "Class E5 Airspace",
      },
      faa_facilities: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/FAA_UAS_FacilityMap_Data/FeatureServer/0",
        color: "#ffa5007a",
        layerName: "FAA Facilities",
      },
      prohibited: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/Prohibited_Areas/FeatureServer/0",
        color: "rgba(255,0,0, 0.5)", //red
        layerName: "UAS Prohibited",
      },
      sua: {
        url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/Pending_Special_Use_Airspace/FeatureServer/0",
        color: "#862051",
        layerName: "Special Use Airspace",
      },
      park: {
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Parks/FeatureServer/0",
        color: "#0080807a",
        layerName: "Parks",
      },
      // noaa: {
      //   url: "https://services2.arcgis.com/C8EMgrsFcRFL6LrL/arcgis/rest/services/NOAA_CORS_Network_Test/FeatureServer/0",
      //   color: "purple",
      // },
      // adhp: {
      //   url: "https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/ADHP/FeatureServer/0/",
      //   color: "yellow",
      //   layerName: "ADHP",
      // },
      power_plant: {
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/PowerPlants_US_EIA_placekeys/FeatureServer/0",
        color: "orange",
        layerName: "Power Plants",
      },
      school: {
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/HIFLD_Public_Schools_Placekey/FeatureServer/0",
        color: "#ff809699",
        layerName: "Schools",
      },
    };
    const initialState = {
      layer: null,
      apilayers: hazardsLayers,
      data: null,
      url: "https://api.airmap.com/airspace/v2/search",
      shouldFetch: false,
      layerUrls,
      isFetching: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "HAZARDS_FETCH_STARTED":
        case "HAZARDS_FETCH_FINISHED":
        case "FEATURES_LOAD_STARTED":
        case "FEATURES_LOAD_FINISHED":
          return Object.assign({}, state, payload);
        case "MISSIONSKML_FETCH_FINISHED":
        case "MISSIONSAOI_FETCH_FINISHED":
        case "MAP_INITIALIZED":
          return Object.assign({}, state, {
            shouldFetch: true,
          });
        case "AIRSPACE_SET_ACTIVE_LAYERS":
          return Object.assign({}, state, {
            shouldLoadFeatures: true,
          });
        default:
          return state;
      }
    };
  },

  doClickHandle:
    () =>
    ({ store }) => {
      let map = store.selectMap();
      if (map) {
        let container = document.getElementById("popup");
        let content = document.getElementById("popup-content");
        let closer = document.getElementById("popup-closer");
        if (container) {
          container.style.display = "block";
        }
        let overlay = new Overlay({
          element: container,
          positioning: "bottom-center",
          stopEvent: false,
          offset: [20, -20],
        });
        if (closer) {
          closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
          };
        }
        map.on("click", (e, idx) => {
          map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
            if (feature.get("hazardType") === "faa_facilities") {
              let name = feature.get("APT1_NAME");
              let ceiling = feature.get("CEILING");
              let unit = feature.get("UNIT");
              let count = feature.get("ARPT_COUNT");
              if (name && content) {
                content.innerHTML = `<div>
                <h6 style=color:#20a8d8>Feature</h6>
                <p><b style="padding-right:4px">Hazard Type:</b>${feature.get(
                  "hazardType"
                )}</p>
                <p><b style="padding-right:4px">Name:</b>${name}</p>
                <p><b style="padding-right:4px">Ceiling:</b>${ceiling}</p>
                <p><b style="padding-right:4px">Unit:</b>${unit}</p>
                <p><b style="padding-right:4px">Airport Count:</b>${count}</p>
                </div>`;
                overlay.setPosition(
                  getCenter(feature.getGeometry().getExtent())
                );
                map.addOverlay(overlay);
              }
            } else {
              let nameKey =
                feature.get("hazardType") !== "power_plant"
                  ? "NAME"
                  : "Plant_Name";
              let name = feature.get(nameKey);
              if (name && content) {
                content.innerHTML = `<div><h6 style=color:#20a8d8>Feature</h6><p><b style="padding-right:4px">Hazard Type:</b>${feature.get(
                  "hazardType"
                )}</p><p><b style="padding-right:4px">Name:</b>${name}</p></div>`;
                overlay.setPosition(
                  getCenter(feature.getGeometry().getExtent())
                );
                map.addOverlay(overlay);
              }
            }
          });
        });
      }
    },

  doFeaturesLoad:
    () =>
    ({ store, dispatch }) => {
      dispatch({
        type: "FEATURES_LOAD_STARTED",
        payload: { shouldLoadFeatures: false, featuresIsLoading: true },
      });
      store.doClickHandle();
      const data = store.selectHazardsData();
      const layerUrls = store.selectLayerUrls();
      const activeLayerNames = store.selectAirspaceActiveLayerNames();
      const layer = store.selectHazardsLayer();
      const map = store.selectMap();
      let activeData =
        data &&
        data.length &&
        data.filter((d) => activeLayerNames.includes(d.properties.hazardType));
      if (activeData) {
        if (layer && map) {
          layer.getSource().clear();
          map.removeLayer(layer);
        }
        if (activeData.length === 0) {
          dispatch({
            type: "FEATURES_LOAD_FINISHED",
            payload: { featuresIsLoading: false },
          });
          return;
        }
        let src = new VectorTileSource();
        let hazardsLayer = new VectorTileLayer({
          source: src,
          style: (feature, resolution) => {
            let geomType = feature.getGeometry().getType();
            let hazardType = feature.get("hazardType");
            if (geomType === "Point") {
              return new Style({
                image: new Circle({
                  radius: 4,
                  fill: new Fill({
                    color: layerUrls[hazardType].color,
                  }),
                  stroke: new Stroke({
                    color: layerUrls[hazardType].color,
                    width: 1,
                  }),
                }),
              });
            } else if (hazardType === "faa_facilities") {
              return new Style({
                fill: new Fill({
                  color:
                    feature.get("APT1_LAANC") !== 0 ? "#dcf3de82" : "#ff2f004a",
                }),
                stroke: new Stroke({
                  color: feature.get("APT1_LAANC") !== 0 ? "green" : "red",
                  width: 1,
                }),
              });
            } else if (hazardType.includes("class")) {
              return new Style({
                stroke: new Stroke({
                  color: layerUrls[hazardType].color,
                  width: 4,
                  lineDash: layerUrls[hazardType].dashed ? [4, 8] : null,
                }),
              });
            } else if (hazardType.includes("sua")) {
              return new Style({
                stroke: new Stroke({
                  color: layerUrls[hazardType].color,
                  width: 2,
                  lineDash: layerUrls[hazardType].dashed ? [4, 8] : null,
                }),
              });
            } else {
              return new Style({
                fill: new Fill({
                  color: layerUrls[hazardType].color,
                }),
              });
            }
          },
        });
        let features = [];
        activeData = activeData.filter((f) =>
          f.properties.hazardType.includes("class_e")
            ? f.properties.hazardType.toUpperCase() === f.properties.LOCAL_TYPE
            : true
        );
        activeData.forEach((f) => {
          features.push(geoJSON.readFeatures(f)[0]);
        });
        src.addFeatures(features);

        if (map) {
          map.addLayer(hazardsLayer);
          dispatch({
            type: "FEATURES_LOAD_FINISHED",
            payload: { featuresIsLoading: false, layer: hazardsLayer },
          });
        }
      }
    },
  doHazardsFetch:
    () =>
    async ({ dispatch, store }) => {
      const layer = store.selectHazardsLayer();
      if (layer) layer.getSource().clear();
      if (!store.selectMissionsByRoute()) {
        dispatch({
          type: "HAZARDS_FETCH_FINISHED",
          payload: { shouldFetch: false, isFetching: false, data: [] },
        });
        return;
      }
      const query = store.selectGisQuery();
      const layerUrls = store.selectLayerUrls();
      let geoJsonFeatures =
        store.selectMissionsAoiItems().length > 0
          ? store.selectMapAoiBufferAsGeoJSON()
          : store.selectMapKmlBufferAsGeoJSON();
      let bufferFeature = geoJsonFeatures.features[0];
      dispatch({
        type: "HAZARDS_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let data = await Promise.all(
        Object.keys(layerUrls).map((type) => {
          let url = `${layerUrls[type].url}/${query}`;
          if (layerUrls[type].query)
            url = `${layerUrls[type].url}/${query.replace(
              "1=1",
              layerUrls[type].query
            )}`;
          return fetch(url).then((res) => res.json());
        })
      );
      // need to inject the hazardType into each feature, so that when we render it, it is easier to style
      // this is because every feature properties object is not exactly the same, so the conditions are exhausting to render different styles
      // if new mission or has no aoi get out of this func tion
      if (!data || !data.length || !bufferFeature) {
        dispatch({
          type: "HAZARDS_FETCH_FINISHED",
          payload: { data: [], isFetching: false },
        });
      } else {
        let hazardsInBbox = data
          .map((d, i) =>
            d.features.map((f) => ({
              ...f,
              properties: {
                hazardType: Object.keys(layerUrls)[i],
                ...f.properties,
              },
            }))
          )
          .flat(1);

        let hazardsInPolygon = [];
        let turfBuffer = polygon(bufferFeature.geometry.coordinates);
        hazardsInBbox.forEach((d) => {
          let turfD;
          if (d.geometry.type === "Polygon") {
            turfD = polygon(d.geometry.coordinates);
          } else if (d.geometry.type === "Point") {
            turfD = point(d.geometry.coordinates);
          } else if (d.geometry.type === "MultiPolygon") {
            turfD = multiPolygon(d.geometry.coordinates);
          }
          turfD = toWgs84(turfD);
          if (booleanIntersects(turfD, turfBuffer)) hazardsInPolygon.push(d);
        });

        dispatch({
          type: "HAZARDS_FETCH_FINISHED",
          payload: {
            data: hazardsInPolygon,
            isFetching: false,
            shouldLoadFeatures: true,
          },
        });
      }
    },

  selectHazardsData: (state) => {
    return state.hazards.data;
  },

  selectHazardsIsFetching: (state) => state.hazards.isFetching,
  selectHazardsFeaturesIsLoading: (state) => state.hazards.featuresIsLoading,

  selectHazardsClassified: createSelector(
    "selectMapAoiAsGeoJSON",
    "selectMapKmlAsGeoJSON",
    "selectHazardsData",
    (aoi, kml, data) => {
      if (!data || !data.length) return null;
      let hazardData = data.filter(
        (d) =>
          ["school", "airport", "power_plant"].indexOf(
            d.properties.hazardType
          ) !== -1
      );
      let aoiFeature =
        aoi.features.length > 0 ? aoi.features[0] : kml.features[0];
      if (!aoiFeature) return null;
      const classified = {
        aoi: {},
        other: {},
      };
      let turfAoiFeature = polygon(aoiFeature.geometry.coordinates);
      hazardData.forEach((d) => {
        let turfD;
        if (d.geometry.type === "Polygon") {
          turfD = polygon(d.geometry.coordinates);
        } else if (d.geometry.type === "Point") {
          turfD = point(d.geometry.coordinates);
        } else if (d.geometry.type === "MultiPolygon") {
          turfD = multiPolygon(d.geometry.coordinates);
        }
        turfD = toWgs84(turfD);
        let bucket = booleanIntersects(turfD, turfAoiFeature) ? "aoi" : "other";
        if (!classified[bucket].hasOwnProperty(d.type))
          classified[bucket][d.type] = [];
        classified[bucket][d.type].push(d.properties);
      });
      return classified;
    }
  ),

  selectHazardsLayer: (state) => {
    return state.hazards.layer;
  },

  selectHazardsApiLayers: (state) => {
    return state.hazards.apilayers;
  },

  selectHazardsUrl: (state) => {
    return state.hazards.url;
  },
  selectLayerUrls: (state) => {
    return state.hazards.layerUrls;
  },

  selectGisQuery: createSelector(
    "selectMapAoiBufferExtent",
    "selectMapKmlBufferExtent",
    (aoiExtent, kmlExtent) => {
      let extent =
        !aoiExtent || aoiExtent.length === 0 || aoiExtent.minX === Infinity
          ? kmlExtent
          : aoiExtent;
      const g = encodeURIComponent(
        `{"xmin":${extent.minX},"ymin":${extent.minY},"xmax":${extent.maxX},"ymax":${extent.maxY},"spatialReference":{"wkid":102100}}`
      );
      return `query?where=1=1&outFields=*&f=geojson&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=${g}&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*&outSR=102100`;
    }
  ),

  reactHazardsShouldFetch: (state) => {
    if (state.hazards.shouldFetch && !state.missionsAoi.isLoading)
      return { actionCreator: "doHazardsFetch" };
  },

  reactAirportsShouldLoad: (state) => {
    if (state.hazards.shouldLoadFeatures)
      return { actionCreator: "doFeaturesLoad" };
  },
};
