import { useEffect } from "react";
import { connect } from "redux-bundler-react";
import PdfGenerator from "./pdf-generator";
import FileUploadCard from "../../../../modules/_shared/file-upload-card/file-upload-card";
import { customComparator } from "../../../_shared/helper";
import StatusCell from "../coa-tab/status-cell";

const GenerateCoa = ({
  missionsByRoute,
  missionsAoiGeoJSON,
  missionsAircraftItems,
  missionsPersonnelItems,
  doFileIoUpload,
  doFileIoDelete,
  doFileIoDownload,
  coaDocsByMission,
  orgsByRoute,
  coaApprovalDocsItems,
  missionsKmlGeoJSON,
}) => {
  let hasAoi =
    missionsAoiGeoJSON &&
    missionsAoiGeoJSON.features &&
    missionsAoiGeoJSON.features.length > 0;
  // this assumes there is only one kml shape in the uploaded file
  let hasKml =
    missionsKmlGeoJSON &&
    missionsKmlGeoJSON.length > 0 &&
    missionsKmlGeoJSON[0].features &&
    missionsKmlGeoJSON[0].features.length > 0;
  let coordinates = hasAoi
    ? missionsAoiGeoJSON.features[0].geometry.coordinates
    : hasKml
    ? missionsKmlGeoJSON[0].features[0].geometry.coordinates
    : null;
  let pdfData = {
    description: missionsByRoute.description,
    org: missionsByRoute.org,
    dod_land: missionsByRoute.dod_land,
    name: missionsByRoute.name,
    max_alt: missionsByRoute.max_alt,
    coordinates,
    date_start: missionsByRoute.date_start,
    date_end: missionsByRoute.date_end,
    crew: missionsPersonnelItems.map((person) => ({
      name: person.name,
      email: person.email,
      phone: person.phone,
    })),
    aircraft: missionsAircraftItems.map((aircraft) => ({
      make: aircraft.make,
      model: aircraft.model,
      sn: aircraft.serial_no,
    })),
  };

  const additionalColumns = [
    {
      headerName: "Reviewed",
      field: "is_reviewed",
      comparator: customComparator,
      cellRenderer: (params) => {
        if (!params.data.is_reviewed) {
          return "Awaiting Review";
        }
        return <StatusCell status={params.data.is_reviewed} />;
      },
    },
    {
      headerName: "Submitted",
      field: "is_submitted",
      comparator: customComparator,
      cellRenderer: (params) => {
        if (!params.data.is_submitted && params.data.is_reviewed) {
          return "Awaiting Submission";
        }
        return <StatusCell status={params.data.is_submitted} />;
      },
    },
    {
      headerName: "Completed",
      field: "is_completed",
      comparator: customComparator,
      cellRenderer: (params) => {
        let approvedDoc = coaApprovalDocsItems.filter(
          (doc) => doc.coa_doc_id === params.data.id
        );
        let isCompleted = approvedDoc.length > 0 && approvedDoc[0].create_date;
        approvedDoc = isCompleted ? approvedDoc[0] : null;
        if (!isCompleted && params.data.is_submitted) {
          return "Awaiting Approval";
        }
        return (
          <div className="d-flex">
            {isCompleted && <StatusCell status={isCompleted} />}
            {isCompleted && (
              <button
                className="btn btn-sm btn-info m-2"
                onClick={() => {
                  doFileIoDownload(approvedDoc.s3_key);
                }}
                title="Download Completed COA"
              >
                <i className="mdi mdi-14px mdi-download" />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-100">
      <div className="d-flex flex-column w-100 mb-3">
        <h5>COA Approval Request</h5>
        <div className="clearfix">
          <div className="float-left mr-3">
            <PdfGenerator jsonData={pdfData} />
          </div>
          <p>
            It is required to generate a COA and have it approved before
            receiving ATPM approval. Please submit at least 60 days in advance
            of mission start date.
          </p>
        </div>
      </div>
      <FileUploadCard
        allowRoles={["*.*"]}
        title="COA Request Document - Only 1 File Allowed. Submit Request Doc Only"
        items={coaDocsByMission}
        onUpload={doFileIoUpload}
        onRemove={doFileIoDelete}
        removeUrl="/coa/docs"
        rel="coa_docs"
        data={{
          mission_id: missionsByRoute.id,
          org_id: orgsByRoute.id,
        }}
        header
        additionalColumns={additionalColumns}
        disabledEditing={coaDocsByMission.length > 0}
        required
      />
    </div>
  );
};

export default connect(
  "selectMissionsByRoute",
  "selectOrgsByRoute",
  "selectMissionsAoiGeoJSON",
  "selectMissionsAoiItems",
  "selectMissionsAircraftItems",
  "selectMissionsPersonnelItems",
  "doFileIoUpload",
  "doFileIoDelete",
  "doFileIoDownload",
  "selectCoaDocsByMission",
  "selectCoaApprovalDocsItems",
  "selectMissionsKmlGeoJSON",
  GenerateCoa
);
