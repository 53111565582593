import React from "react";

class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
    };
    this.setDragging = this.setDragging.bind(this);
    this.unsetDragging = this.unsetDragging.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  setDragging(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDragging: true,
    });
  }

  unsetDragging(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDragging: false,
    });
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    const { onDrop } = this.props;
    this.setState(
      {
        isDragging: false,
      },
      () => {
        if (onDrop && typeof onDrop === "function") onDrop(e.nativeEvent);
      }
    );
  }

  render() {
    const { isDragging } = this.state;
    const { children, overlayMsg, overlayClass, overlayStyle } = this.props;
    const overlayStyleConfig = Object.assign(
      {
        display: isDragging ? "block" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 9,
        textAlign: "center",
        verticalAlign: "middle",
        opacity: "0.8",
        fontSize: "large",
        paddingTop: "4px",
      },
      overlayStyle
    );
    return (
      <div
        onDragOver={this.setDragging}
        onDrop={this.handleDrop}
        style={{ position: "relative" }}
      >
        {children}
        <div
          className={overlayClass}
          style={overlayStyleConfig}
          onDragLeave={this.unsetDragging}
        >
          {overlayMsg}
        </div>
      </div>
    );
  }
}

export default DropZone;
