import TileLayer from "ol/layer/Tile";
import basemaps from "../config/basemaps";
import { getLayer, getSource } from "../utils/layer-utils";
import { createSelector } from "redux-bundler";

export default {
  name: "basemaps",

  getReducer: () => {
    const initialState = {
      lyr: null,
      src: null,
      basemaps: basemaps,
      activeIdx: 0,

      shouldCreateBasemapLayer: true,
      shouldSetBasemap: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "BASEMAP_CHANGED":
        case "BASEMAP_SET_STARTED":
        case "BASEMAP_SET_FINISHED":
        case "BASEMAP_CREATE_LAYER_STARTED":
        case "BASEMAP_CREATE_LAYER_FINISHED":
          return Object.assign({}, state, payload);
        case "MAP_INITIALIZED":
        case "DASHBOARD_MAP_INITIALIZED":
          return Object.assign({}, state, {
            ...payload,
            shouldSetBasemap: true,
          });
        case "URL_UPDATED":
          return Object.assign({}, state, { activeIdx: 0 });
        default:
          return state;
      }
    };
  },

  doBasemapsChange:
    (idx) =>
    ({ dispatch }) => {
      dispatch({
        type: "BASEMAP_CHANGED",
        payload: { shouldSetBasemap: true, activeIdx: idx },
      });
    },

  doBasemapsSet:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BASEMAP_SET_STARTED",
        payload: { shouldSetBasemap: false },
      });
      const map = store.selectMissionsByRoute()
        ? store.selectMap()
        : store.selectDashboardMap();
      const lyr = store.selectBasemapsLayer();
      const config = store.selectBasemapsConfig();
      const newSource = getSource(config);
      const currentSource = lyr.getSource();
      if (currentSource !== newSource) {
        const newLayer = new TileLayer({
          source: getSource(config),
        });

        map.removeLayer(lyr); // Remove old layer
        map.getLayers().insertAt(0, newLayer); // Add new layer
        dispatch({
          type: "BASEMAP_SET_FINISHED",
          payload: { src: newSource, lyr: newLayer },
        });
      } else {
        console.log("Source is the same, no need to update layer.");
      }
    },

  doBasemapsCreateLayer:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "BASEMAP_CREATE_LAYER_STARTED",
        payload: { shouldCreateBasemapLayer: false },
      });
      const lyr = getLayer({ serviceType: "XYZ", visible: true });
      console.log(lyr);
      dispatch({
        type: "BASEMAP_CREATE_LAYER_FINISHED",
        payload: { lyr: lyr },
      });
    },

  selectBasemapsConfig: createSelector(
    "selectBasemapsActiveIdx",
    "selectBasemaps",
    (idx, basemaps) => {
      return basemaps[idx];
    }
  ),

  selectBasemapsActiveIdx: (state) => {
    return state.basemaps.activeIdx;
  },

  selectBasemaps: (state) => {
    return state.basemaps.basemaps;
  },

  selectBasemapsLayer: (state) => {
    return state.basemaps.lyr;
  },

  reactBasemapsShouldCreateLayer: (state) => {
    if (state.basemaps.shouldCreateBasemapLayer)
      return { actionCreator: "doBasemapsCreateLayer" };
  },

  reactShouldSetBasemap: (state) => {
    if (state.basemaps.shouldSetBasemap)
      return { actionCreator: "doBasemapsSet" };
  },
};
