import React from "react";
import DropZone from "./drop-zone";

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.upload = this.upload.bind(this);
    this.clear = this.clear.bind(this);
  }

  handleDrop(e) {
    const files = [];
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        if (item.kind === "file") {
          files.push(item.getAsFile());
        }
      }
    } else if (e.dataTransfer.files) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        files.push(file);
      }
    }
    this.setState({ files: files, isDragging: false });
  }

  handleInputChange(e) {
    this.setState({ files: [...this.input.files] });
  }

  handleInputClick(e) {
    this.input.click();
  }

  clear() {
    this.setState({
      files: [],
    });
  }

  upload() {
    const { onUpload, rel, data, url } = this.props;
    const { files } = this.state;
    if (!files.length) return console.log("nothing here");
    if (onUpload && typeof onUpload === "function")
      onUpload(url, files[0], rel, data, this.clear);
  }

  render() {
    const { files } = this.state;
    const filename = files.length ? files[0].name : "";
    return (
      <DropZone
        onDrop={this.handleDrop}
        overlayMsg="Drop Here!"
        overlayClass="bg-primary text-white"
      >
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              className="btn btn-primary"
              onClick={this.handleInputClick}
              title="Browse for files"
            >
              <i className="mdi mdi-folder-open"></i>
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.clear}
              disabled={filename === ""}
              title="Clear selection"
            >
              <i className="mdi mdi-close-circle-outline"></i>
            </button>
          </div>
          <input
            className="form-control"
            value={filename}
            onChange={(e) => {}}
            placeholder="No File Selected..."
          ></input>
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.upload}
              disabled={filename === ""}
              title="Upload Selection"
            >
              <i className="mdi mdi-cloud-upload pr-1"></i>Upload
            </button>
          </div>
          <input
            style={{ display: "none" }}
            ref={(el) => {
              this.input = el;
            }}
            type="file"
            onChange={this.handleInputChange}
            accept={this.props.accept}
          ></input>
        </div>
      </DropZone>
    );
  }
}

export default FileUploader;
