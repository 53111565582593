import { useRef } from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import KML from "ol/format/KML";
import GeoJSON from "ol/format/GeoJSON";

const geoJSON = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});
const UploadKMLButton = (props) => {
  const {
    disabled,
    doMissionsKmlSave,
    doMapKmlLoadFromMission,
    doMissionsKmlFetch,
    missionsKmlItems,
    doMapKmlInitialize,
    doMapClearFeatures,
  } = props;
  const inputRef = useRef();

  const onClick = () => {
    props.onClick();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const kmlText = e.target.result;
        const kmlFormat = new KML();
        const parsedFeatures = kmlFormat.readFeatures(kmlText, {
          featureProjection: "EPSG:3857",
        });
        const geoJsonFeatures = parsedFeatures
          .map((feature) => {
            return geoJSON.writeFeature(feature);
          })
          .map((f) => JSON.stringify(JSON.parse(f).geometry));
        let updatedObject =
          missionsKmlItems.length > 0
            ? { ...missionsKmlItems[0], shapes: geoJsonFeatures }
            : { shapes: geoJsonFeatures };
        doMissionsKmlSave(updatedObject, doMapClearFeatures);
      };
      reader.readAsText(file);
    }
  };

  const btnClass = classnames({
    btn: true,
    "btn-outline-primary": true,
    "mr-2": true,
  });
  return (
    <button
      className={btnClass}
      onClick={onClick}
      disabled={disabled}
      title="Upload a shape file to the map"
    >
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".kml,.kmz"
      />
      <i className="mdi mdi-upload"></i>
    </button>
  );
};

export default connect(
  "selectMap",
  "selectMissionsByRoute",
  "doDialogOpen",
  "doMissionsKmlSave",
  "doMapKmlLoadFromMission",
  "selectMissionsKmlItems",
  "doMissionsKmlFetch",
  "doMapKmlInitialize",
  "doMapClearFeatures",
  UploadKMLButton
);
