import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import {
  snakeCase,
  mapKeys,
  capitalize,
  endsWith,
  find,
  orderBy,
  keyBy,
} from "lodash";
export default createRestBundle({
  name: "equipmentRequest",
  uid: "slug",
  prefetch: true,
  persist: false,
  routeParam: "equipmentRequestSlug",
  getTemplate: "/orgs/:orgSlug/equipment-requests?:queryString",
  putTemplate: "/orgs/:orgSlug/equipment-requests/:item.slug",
  postTemplate: "/orgs/:orgSlug/equipment-requests",
  deleteTemplate: "/orgs/:orgSlug/equipment-requests/:item.slug",
  urlParamSelectors: ["selectEquipmentRequestFormattedQueryString"],
  fetchActions: ["AUTH_LOGGED_IN", "URL_UPDATED"],
  forceFetchActions: [
    "EQUIPMENTREQUEST_STATUS_CHANGING",
    "EQUIPMENTREQUEST_SAVE_FINISHED",
    "EQUIPMENTREQUEST_DELETE_FINISHED",
  ],
  initialData: {
    _selected: null,
    _isStatusChanging: false,
    _queryString: {
      _type: "All",
      _orgId: null,
      _status: "New",
      _profileId: null,
      _action: null,
    },
  },
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "EQUIPMENTREQUEST_INIT_URL_PARAMS":
      case "EQUIPMENTREQUEST_STATUS_CHANGED":
      case "EQUIPMENTREQUEST_STATUS_CHANGED_ERROR":
      case "EQUIPMENTREQUEST_SELECTED":
      case "EQUIPMENTREQUEST_QUERY_STRING_UPDATED":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    selectEquipmentRequestDeactivationTypes: createSelector(
      "selectDomainsItemsByGroup",
      (domainsItemsByGroup) => {
        return domainsItemsByGroup["equipment_status"].filter(
          (status) => !["In Inventory", "Display Only"].includes(status.val)
        );
      }
    ),
    selectEquipmentRequestOrgOptions: createSelector(
      "selectOrgsItems",
      "selectEquipmentRequestCountsItemsObject",
      (orgsItems, equipmentRequestCountsItemsObject) => {
        let allOrgCounts = {
          ...keyBy(
            orgsItems.map((o) => {
              return {
                ...o,
                org_name: o.name,
                combined_count: null,
                add_count: null,
                deactivate_count: null,
              };
            }),
            "id"
          ),
          ...equipmentRequestCountsItemsObject,
        };
        return [
          { label: "All", value: "All" },
          ...orderBy(
            Object.values(allOrgCounts).map((org) => {
              return { ...org, label: org.org_name, value: org.id };
            }),
            ["label"],
            ["asc"]
          ),
        ];
      }
    ),
    selectEquipmentRequestAdminIsViewing: createSelector(
      "selectRouteInfo",
      (routeInfo) => {
        return routeInfo.pattern.includes("/:orgSlug/equipment-requests");
      }
    ),
    selectEquipmentRequestQueriedOrg: createSelector(
      "selectEquipmentRequestOrgOptions",
      "selectEquipmentRequestQueryString",
      "selectOrgsByRoute",
      (
        equipmentRequestOrgOptions,
        equipmentRequestQueryString,
        orgsByRoute
      ) => {
        const { _orgId } = equipmentRequestQueryString;
        let orgsByRouteId = orgsByRoute ? orgsByRoute.id : null;
        let found = find(equipmentRequestOrgOptions, [
          "id",
          _orgId ? _orgId : orgsByRouteId,
        ]);
        if (found) return found;
        return { label: "All", value: "All" };
      }
    ),
    selectEquipmentRequestQueryString: (state) => {
      return state.equipmentRequest._queryString;
    },
    selectEquipmentRequestSelected: (state) => {
      return state.equipmentRequest._selected;
    },
    selectEquipmentRequestQueriedType: (state) => {
      let { _queryString } = state.equipmentRequest;
      return _queryString._type;
    },
    selectEquipmentRequestQueriedStatus: (state) => {
      let { _queryString } = state.equipmentRequest;
      return _queryString._status;
    },
    selectEquipmentRequestItemsOrderedByStatus: createSelector(
      "selectEquipmentRequestItems",
      (equipmentRequestItems) => {
        return orderBy(equipmentRequestItems, ["status"], ["desc"]);
      }
    ),
    selectEquipmentRequestUserIsAdmin: createSelector(
      "selectTokenRolesJoined",
      (tokenRolesJoined) => {
        return (
          tokenRolesJoined.includes("APP.SYSADMIN") ||
          tokenRolesJoined.includes("HQ.ADMIN")
        );
      }
    ),
    doEquipmentRequestUpdateSelected:
      (item, callback) =>
      ({ dispatch, store }) => {
        dispatch({
          type: "EQUIPMENTREQUEST_SELECTED",
          payload: { _selected: item },
        });
        callback && callback();
      },

    selectEquipmentRequestFormattedQueryString: createSelector(
      "selectEquipmentRequestQueryString",
      "selectEquipmentRequestAdminIsViewing",
      (equipmentRequestQueryString, equipmentRequestAdminIsViewing) => {
        let mapped = mapKeys(equipmentRequestQueryString, (val, key) =>
          snakeCase(key)
        );
        if (equipmentRequestAdminIsViewing) {
          mapped = { ...mapped, profile_id: "All" };
        }
        return {
          queryString: Object.keys(mapped)
            .map((key) => {
              if (mapped[key])
                return `${key}=${encodeURIComponent(mapped[key])}`;
            })
            .join("&"),
        };
      }
    ),
    doEquipmentRequestInitUrlParams:
      () =>
      ({ dispatch, store }) => {
        const profileId = store.selectProfileId();
        const routeInfo = store.selectRouteInfo();
        const { pattern: routePattern } = routeInfo;
        const onDetailsPage = routePattern.includes(
          "/:orgSlug/equipment-requests/:equipmentRequestSlug"
        );
        const onRequestsPage =
          !onDetailsPage &&
          routePattern.includes("/:orgSlug/equipment-requests");
        const queryString = store.selectEquipmentRequestQueryString();

        // Default query values combined with user values
        let updatedQueryData = {
          ...queryString,
          _profileId: profileId,
        };
        // When initializing query params, add the previous query params saved in localStorage
        let storedQueryItem = window.localStorage.getItem(
          `uas-mgr-ui-equipment-requests-data`
        );
        if (storedQueryItem) {
          let storedQueryData = JSON.parse(storedQueryItem, (key, val) =>
            val === "null" ? null : val
          );
          // If page refreshed while on a request's page, keep the stored query values
          if (onDetailsPage) {
            updatedQueryData = { ...updatedQueryData, ...storedQueryData };
          }
          // Else, update the stored query data with the default query values
          else updatedQueryData = { ...storedQueryData, ...updatedQueryData };
        }
        if (!onRequestsPage && !onDetailsPage)
          updatedQueryData = { ...updatedQueryData, _orgId: null };

        // Finds the directory after orgSlug in the route's pattern (will be aircraft, gcs, payloads, or equipment-requests)
        const typeFinderByRoute = new RegExp("(?<=:orgSlug/)(.*?)(?=/|$)");

        // Execute the regexp on routeInfo.pattern. If 'payloads', remove the 's' at the end.
        let type = typeFinderByRoute.exec(routePattern)?.[0];
        if (type === "payloads") type = "payload";

        // If user isn't on equipment-requests page, update the query param for '_type' to what was found above
        let updatedByRoute = {
          ...updatedQueryData,
          _status: "New",
          _type: type === "equipment-requests" ? updatedQueryData._type : type,
        };
        updatedQueryData = { ...updatedQueryData, ...updatedByRoute };
        window.localStorage.setItem(
          `uas-mgr-ui-equipment-requests-data`,
          JSON.stringify(updatedQueryData)
        );
        dispatch({
          type: "EQUIPMENTREQUEST_INIT_URL_PARAMS",
          payload: {
            _shouldFetch: true,
            _queryString: {
              ...updatedQueryData,
            },
          },
        });
      },
    doEquipmentRequestUpdateQueryString:
      (update) =>
      ({ dispatch, store }) => {
        let queryStr = store.selectEquipmentRequestQueryString();
        let updatedQueryStr = { ...queryStr, ...update };

        let queryCookie = window.localStorage.getItem(
          "uas-mgr-ui-equipment-requests-data"
        );

        let queryParams = {};
        if (queryCookie) {
          queryParams = JSON.parse(queryCookie, (key, val) =>
            val === "null" ? null : val
          );
          Object.keys(updatedQueryStr).forEach((key) => {
            queryParams[key] = `${updatedQueryStr[key]}`;
          });
        }

        window.localStorage.setItem(
          "uas-mgr-ui-equipment-requests-data",
          JSON.stringify(queryParams)
        );

        dispatch({
          type: "EQUIPMENTREQUEST_QUERY_STRING_UPDATED",
          payload: {
            _shouldFetch: true,
            _queryString: updatedQueryStr,
          },
        });
      },
    doEquipmentRequestChangeStatus:
      (status, callback) =>
      ({ dispatch, store, apiPut }) => {
        let selected = store.selectEquipmentRequestSelected();
        let { action, type, slug } = selected;
        const tokenPayload = store.selectTokenPayload();

        let statusRouteParam = "";
        if (status === "approve") {
          if (action === "Add") statusRouteParam = "approve" + capitalize(type);
          else statusRouteParam = "deactivate" + capitalize(type);
        } else statusRouteParam = status;
        let url = `${store.selectApiRoot()}/orgs/hq/equipment-requests/${statusRouteParam}/${slug}`;
        dispatch({
          type: "EQUIPMENTREQUEST_STATUS_CHANGING",
          payload: {
            _isStatusChanging: true,
          },
        });
        delete selected.image;
        apiPut(
          url,
          {
            ...selected,
            title: "",
            comment: selected.comment ? selected.comment : "",
            status: `${capitalize(status)}${
              endsWith(status, "e") ? "d" : "ed"
            }`,
            reviewed_on: new Date(),
            reviewed_by: tokenPayload.sub,
            deactivated: action !== "Add" ? action : null,
          },
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "EQUIPMENTREQUEST_STATUS_CHANGED_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  _isStatusChanging: false,
                },
              });
            } else {
              dispatch({
                type: "EQUIPMENTREQUEST_STATUS_CHANGED",
                payload: {
                  _selected: null,
                  _shouldFetch: true,
                  _isStatusChanging: false,
                },
              });
              callback && callback();
            }
          }
        );
      },
  },
});
